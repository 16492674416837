import momentTz from "moment-timezone";

const getTimeBounds = (calendarDate, timezone) => {
  const startHour = momentTz
    .tz(calendarDate?.toISOString() || momentTz(), timezone)
    .startOf("day");
  const endHour = momentTz
    .tz(calendarDate?.toISOString() || momentTz(), timezone)
    .endOf("day");

  return {
    startHour,
    endHour,
  };
};

const generateTimeSlots = (calendarDate, timeInterval, timezone) => {
  let { startHour: startMoment, endHour: endMoment } = getTimeBounds(
    calendarDate,
    timezone
  );

  const timeSlots = [];

  let slotMoment = startMoment.clone();

  while (slotMoment.isBefore(endMoment)) {
    timeSlots.push({
      id: slotMoment.clone().format("HHmm"),
      time: slotMoment.clone(), // we clone the moment to avoid mutation issues
    });
    slotMoment.add(timeInterval, "minutes");
  }

  return timeSlots;
};

const changeMinutes = (event, duration, timezone, calendarDate) => {
  const { start, end } = event;
  const previousDurationInMs = end.diff(start);

  let startMoment = start.clone().add(duration, "minutes");
  let endMoment = end.clone().add(duration, "minutes");

  const { startHour: startOfDay, endHour: endOfDay } = getTimeBounds(
    calendarDate,
    timezone
  );

  if (startMoment.isBefore(startOfDay)) {
    startMoment = startOfDay;
  }

  if (endMoment.isSameOrAfter(endOfDay)) {
    endMoment = endOfDay.clone();
    startMoment = endMoment
      .clone()
      .subtract(previousDurationInMs, "milliseconds");
  }

  return {
    newStart: startMoment,
    newEnd: endMoment,
  };
};

const detectTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};
const processDate = (time) => {
  return time.format("hh:mm A");
};

const handleScrollResourceHeader = (firstDivRef, SecDivRef) => () => {
  if (firstDivRef.current && SecDivRef.current) {
    requestAnimationFrame(() => {
      firstDivRef.current.scrollLeft = SecDivRef.current.scrollLeft;
    });
  }
};

export {
  handleScrollResourceHeader,
  generateTimeSlots,
  detectTouchDevice,
  changeMinutes,
  getTimeBounds,
  processDate,
};
