import { Button } from "antd";
import "./styles.scss";

const Illustration = ({
  img,
  message,
  subMessage,
  buttonProps,
  wrapperStyles,
}) => {
  return (
    <div className="illustration-wrapper" style={wrapperStyles}>
      <img src={img} alt={"illustration"} />
      {message && <h3>{message}</h3>}
      {subMessage && <p className="subtle-text">{subMessage}</p>}
      {buttonProps && <Button {...buttonProps} />}
    </div>
  );
};

export default Illustration;
