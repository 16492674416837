import { MODULES } from "abilities/definitions";
import { AiOutlineSchedule } from "react-icons/ai";
import { CiStethoscope } from "react-icons/ci";
import { GoPerson } from "react-icons/go";
import { HiOutlineHome } from "react-icons/hi2";
import { LiaLockOpenSolid } from "react-icons/lia";
import { PiCalendarCheck, PiCalendarDuotone } from "react-icons/pi";
import { RiSettings3Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const navItems = [
  {
    key: "/",
    icon: <AiOutlineSchedule />,
    label: <Link to={"/"}>Board</Link>,
    module: MODULES.BOARD,
  },
  {
    key: "/end-of-day",
    icon: <RiSettings3Line />,
    label: <Link to={"end-of-day"}>End Of Day Confirmation</Link>,
    module: MODULES.EOD_CONFIRMATION,
  },
  {
    key: "/procedures",
    icon: <CiStethoscope />,
    label: <Link to={"/procedures"}>Procedures</Link>,
    module: MODULES.PROCEDURES,
  },
  {
    key: "/block-schedule/configure",
    icon: <GoPerson />,
    label: (
      <Link to={"/block-schedule/configure"}>Configure Block Schedule</Link>
    ),
    module: MODULES.BLOCK_SCHEDULES,
  },
  {
    key: "/go-home-list",
    icon: <HiOutlineHome />,
    label: <Link to={"/go-home-list"}>Go Home List</Link>,
    module: MODULES.GO_HOME_LIST,
  },
  {
    key: "/gas-shifts-calendar",
    icon: <PiCalendarCheck />,
    label: <Link to={"/gas-shifts-calendar"}>Gas Shifts Calendar</Link>,
    module: MODULES.GAS_SHIFTS_CALENDAR,
  },
  {
    key: "/gas-shifts-annual-calendar",
    icon: <PiCalendarDuotone />,
    label: (
      <Link to={"/gas-shifts-annual-calendar"}>Gas Shifts Annual Calendar</Link>
    ),
    module: MODULES.GAS_SHIFTS_CALENDAR,
  },

  {
    key: "/gas-open-shifts-calendar",
    icon: <LiaLockOpenSolid />,
    label: (
      <Link to={"/gas-open-shifts-calendar"}>Gas Open Shifts Calendar</Link>
    ),
    module: MODULES.GAS_SHIFTS_CALENDAR,
  },
];

export default navItems;
