// refresh.js
import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "constants/common";

const refreshToken = async () => {
    const refresh = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/auth/token/refresh`,
            { headers: { 'Authorization': 'Bearer ' + refresh } }
        );
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, response.data?.data);
        return response.data?.data;
    } catch (error) {
        throw error;
    }
};

export default refreshToken;
