import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  vapidKey: process.env.REACT_APP_VAPID_KEY,
};

let messaging, analytics;

// Check environment and protocol
if (
  process.env.NODE_ENV === "development" ||
  window.location.protocol === "https:"
) {
  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
  analytics = getAnalytics(firebaseApp);
} else {
  console.error("Firebase can only be initialized over HTTPS in production");
}

export { messaging, analytics };
