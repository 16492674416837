import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "constants/common";
import { onLogout } from "services/redux/slices/user";
import store from "services/redux/store";
import refreshToken from "./refresh";

// embedding timezone in api call.

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

store.subscribe(() => {
  const storeState = store.getState();
  const timezone = storeState?.user?.user?.timezone;
  if (timezone && !axiosInstance.defaults.headers["X-Timezone"]) {
    axiosInstance.defaults.headers["X-Timezone"] = timezone;
  }
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refresh = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

    if (error?.response?.status === 401 && refresh && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const access_token = await refreshToken();
        originalRequest.headers["Authorization"] = "Bearer " + access_token;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // dispatch logout user from store
        store.dispatch(onLogout());
        return Promise.reject(refreshError);
      }
    }

    if (error?.response?.status === 401 && !refresh) {
      // dispatch logout user from store
      store.dispatch(onLogout());
    }

    if (error?.response?.status === 422) {
      localStorage.clear()
      store.dispatch(onLogout());
    }

    return Promise.reject(error);
  }
);

// to add token in request if present
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
