import { timeInterval } from "globalComponents/Schedular/config";
import { useCallback, useEffect } from "react";
import { useDrop } from "react-dnd";

const useCellWrapper = ({
  handleOverlayDrop,
  setIsCalendarRendered,
  isCalendarRendered,
  handleSelectSlot,
}) => {
  const [, drop] = useDrop(
    () => ({
      accept: "event",
      drop: (item, monitor) => {
        const differenceFromInitialOffset =
          monitor.getDifferenceFromInitialOffset();
        const clientOffset = monitor.getClientOffset();
        const sourceClientOffset = monitor.getSourceClientOffset();

        handleOverlayDrop({
          differenceFromInitialOffset,
          sourceClientOffset,
          clientOffset,
          item,
        });
        return item;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [handleOverlayDrop]
  );

  useEffect(() => {
    isCalendarRendered === false && setIsCalendarRendered(true);
  }, [setIsCalendarRendered, isCalendarRendered]);

  const handleSelectSlotCb = useCallback(
    (slot, resource) => {
      const { time: start } = slot;
      const end = new Date(start);
      end.setMinutes(end.getMinutes() + timeInterval);
      handleSelectSlot &&
        handleSelectSlot({
          slots: [start, end],
          resourceId: resource?.id,
        });
    },
    [handleSelectSlot]
  );

  return { drop, handleSelectSlotCb };
};

export default useCellWrapper;
