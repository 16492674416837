import { Resource } from "./components";

const ResourceHeader = ({
  resources,
  customComponent = {},
  handleResourceDropCb,
  allowDropOnSchedular,
}) => {
  return (
    <div className="resource-header">
      <div className="resource-gutter" />
      <div className="resource-header-inner">
        {resources.map((resource) => (
          <Resource
            key={resource.id}
            resource={resource}
            customComponent={customComponent}
            handleResourceDropCb={handleResourceDropCb}
            allowDropOnSchedular={allowDropOnSchedular}
          />
        ))}
      </div>
    </div>
  );
};

export default ResourceHeader;
