import React from "react";

const CardLayout = ({ title = "", headerActions = [], children, className }) => {
  return (
    <div className={`card-layout ${className ? className : ""}`}>
      <div className="card-layout-header">
        <h3 className="font-monstserrat">{title}</h3>
      </div>
      <div className="card-layout-body">{children}</div>
    </div>
  );
};

export default CardLayout;
