import { createAsyncThunk } from "@reduxjs/toolkit";
import { defineAbilityFor } from "abilities";
import { ROLES } from "abilities/definitions";
import {
  AUTH_SOURCE,
  DEFAULT_FACILITY,
  DEFAULT_TIMEZONE,
  LOCAL_STORAGE_KEYS,
} from "constants/common";
import { onLogin } from "../slices/user";
import { processNotifications } from "./notification";

export const processUserLogin = createAsyncThunk(
  "user/processUserLogin",
  async ({ apiResponse, ability, type }, { dispatch }) => {
    let user = {
      ...apiResponse,
      facility:
        Object.keys(apiResponse.facility || {}).length > 0
          ? apiResponse.facility
          : DEFAULT_FACILITY,
      timezone: DEFAULT_TIMEZONE,
      newRegistration: !!apiResponse?.newRegistration,
    };

    defineAbilityFor(ability, user.role);
    dispatch(onLogin(user));

    if (type === AUTH_SOURCE.LOGIN) {
      const { access, refresh } = apiResponse;
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, access);
      localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh);
    }

    if (user.role === ROLES.PROVIDER) {
      dispatch(processNotifications());
    }
  }
);
