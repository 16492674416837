import { combineReducers } from "@reduxjs/toolkit";
import { user, layout, notification } from "./slices";

const rootReducer = combineReducers({
  user,
  layout,
  notification,
});

export default rootReducer;
