import { Tour } from "antd";
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {
  const [isTourVisible, setTourVisible] = useState(false);
  const [steps, setSteps] = useState([]);
  const refs = useRef({});
  const [onFinish, setOnFinish] = useState(null); // Maintain onFinish state

  const startTour = useCallback(() => {
    setTourVisible(true);
  }, []);

  const endTour = useCallback(() => {
    setTourVisible(false);
  }, []);

  const registerRef = useCallback((key, ref) => {
    refs.current[key] = ref;
  }, []);

  const value = {
    isTourVisible,
    steps,
    setSteps,
    startTour,
    endTour,
    registerRef,
    refs: refs.current,
    setOnFinish,
  };

  return (
    <TourContext.Provider value={value}>
      {children}
      {steps.length > 0 && (
        <Tour
          open={isTourVisible}
          steps={steps.map((step) => ({
            ...step,
            target: refs.current[step.targetKey],
          }))}
          onClose={endTour}
          onFinish={onFinish}
        />
      )}
    </TourContext.Provider>
  );
};
