import { Button, Modal } from "antd";
import { PROVIDER_ONBOARDING } from "assets";

import "./styles.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ProviderOnBoard = ({ user, isTourRequired }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(isTourRequired);

  const handleNavigation = () => {
    setIsOpen(false);
    navigate("/gas-shifts-annual-calendar");
  };

  return (
    <Modal open={isOpen} footer={false} closable={false}>
      <div className="provider-on-board">
        <img src={PROVIDER_ONBOARDING} alt="provider-onboarding" />
        <div className="provider-on-board-texts">
          <h2 className="m-0">Welcome {user?.name}!</h2>
          <p className="m-0 secondary-text-color">
            We have noticed that you are new and might want to take a tour of
            the application
          </p>
        </div>
        <Button
          onClick={handleNavigation}
          type="primary"
          block
          size="lg"
          icon={<ArrowRightOutlined />}
        >
          Begin Tour
        </Button>
      </div>
    </Modal>
  );
};

export default ProviderOnBoard;
