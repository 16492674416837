import { message } from "antd";
import { ME } from "constants/apiEndpoints";
import { AUTH_SOURCE, AXIOS_METHODS } from "constants/common";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useFirebaseMessages from "@firebase/hooks/useFirebaseMessages";
import { AbilityContext } from "contexts/can";
import { useAxios, useLaterEffect, useNetworkState } from "hooks";
import { processUserLogin } from "services/redux/thunk/user";

const useApp = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const ability = useContext(AbilityContext);
  const networkState = useNetworkState();
  const dispatch = useDispatch();
  useFirebaseMessages();

  const { response: meApiResponse, success: meApiSuccess } = useAxios({
    method: AXIOS_METHODS.GET,
    url: ME,
  });

  useEffect(() => {
    if (meApiSuccess) {
      dispatch(
        processUserLogin({
          apiResponse: meApiResponse,
          type: AUTH_SOURCE.ME,
          ability,
        })
      );
    }
  }, [meApiSuccess, meApiResponse, ability, dispatch]);

  useLaterEffect(() => {
    if (networkState.isOnline) {
      message.success("You are back online!");
    } else {
      message.error("You are offline!");
    }
  }, [networkState.isOnline]);

  return { isAuthenticated };
};

export default useApp;
