import { AppLoader } from "globalComponents";
import useApp from "useApp";
import RouterConfig from "./navigation";

function App() {
  const { isAuthenticated } = useApp();

  return (
    <>
      <AppLoader
        loading={isAuthenticated === null}
        transparent={false}
        appEntry
      />
      {isAuthenticated !== null && <RouterConfig />}
    </>
  );
}

export default App;
