import useCellWrapper from "./useCellWrapper";

const CellsWrapper = ({
  resources,
  timeSlots,
  handleCellRef,
  handleColumnRef,
  setIsCalendarRendered,
  isCalendarRendered,
  handleOverlayDrop,
  handleSelectSlot = null,
  allowDropOnSchedular,
  children,
}) => {
  const { drop, handleSelectSlotCb } = useCellWrapper({
    isCalendarRendered,
    setIsCalendarRendered,
    handleOverlayDrop,
    handleSelectSlot,
  });

  return (
    <div className="cells-wrapper" ref={allowDropOnSchedular ? drop : null}>
      {resources.map((resource, resourceIndex) => (
        <div
          className={`cell-group ${
            resourceIndex === resources.length - 1 ? "last" : ""
          }`}
          key={resource.id}
          ref={(ref) => handleColumnRef(ref, resource.id, resourceIndex)}
        >
          {timeSlots.map((timeSlot, cellIndex) => (
            <div
              onClick={() => handleSelectSlotCb(timeSlot, resource)}
              key={timeSlot.id}
              className="cell"
              ref={(ref) =>
                handleCellRef(
                  ref,
                  resource.id,
                  timeSlot.id,
                  cellIndex + resourceIndex
                )
              }
            />
          ))}
        </div>
      ))}
      {children}
    </div>
  );
};

export default CellsWrapper;
