import { useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

const useEvent = ({
  getDroppedEventStyles,
  handleResizeEvent,
  setPreviousCalendarScrollOffset,
  event,
  handleDropOnEventCb,
}) => {
  const { height: initialHeight, ...styles } = useMemo(
    () => getDroppedEventStyles(event),
    [event, getDroppedEventStyles]
  );

  const [disableResize, setDisableResize] = useState(false);
  const [height, setHeight] = useState(initialHeight);

  useEffect(() => {
    setHeight(initialHeight);
  }, [event, setHeight, initialHeight]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "event",
      item: event,

      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: () => !disableResize,
    }),
    [disableResize, event]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "item-on-event",
      drop: (item) => {
        handleDropOnEventCb({ ...event, ...item });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  const onResize = (_, { size }) => {
    setHeight(size.height);
  };

  const handleResizeStop = () => {
    setDisableResize(false);
    handleResizeEvent({ item: event, height });
  };

  useEffect(() => {
    const eventCardElement = document.getElementById(`event-card-${event.id}`);
    if (eventCardElement) {
      if (isDragging) {
        setPreviousCalendarScrollOffset();
        eventCardElement.style.transition = "0.3s all";
      } else {
        setTimeout(() => {
          eventCardElement.style.transition = "none";
        }, 300);
      }
    }
  }, [isDragging, event.id, setPreviousCalendarScrollOffset]);

  return {
    styles,
    isDragging,
    setDisableResize,
    handleResizeStop,
    onResize,
    drop,
    drag,
    height,
  };
};

export default useEvent;
