import { Button, Divider, Form, Input, Radio } from "antd";
import useSignup from "./useSignup";

const Signup = () => {
  const { handleNavigation, handleRegistration } = useSignup();

  return (
    <div className="h-100 w-100">
      <h3 className="font-bold uppercase">Signup</h3>
      <Form layout="vertical" onFinish={handleRegistration}>
        <Form.Item
          rules={[{ message: "Name is rquired", required: true }]}
          label="Name"
          name={"name"}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ message: "Username is rquired", required: true }]}
          label="Username"
          name={"username"}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ message: "Email is rquired", required: true }]}
          label="Email"
          name={"email"}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name={"password"}
          rules={[{ message: "Password is rquired", required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ message: "Please select Role", required: true }]}
        >
          <Radio.Group>
            <Radio value={"admin"}>Chief</Radio>
            <Radio value={"provider"}>Provider</Radio>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" block htmlType="submit">
          Signup
        </Button>
      </Form>

      <p
        className="text-small subtle-text text-center"
        style={{ marginTop: 10 }}
      >
        Already have an account ?{" "}
        <mark
          className="primary-text-color cursor-pointer"
          onClick={() => handleNavigation("/auth/login")}
        >
          Login
        </mark>
      </p>
      <Divider>OR</Divider>
    </div>
  );
};

export default Signup;
