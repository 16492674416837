import { lazy } from "react";

// Use React.lazy for importing components lazily
export const Providers = lazy(() => import("./Providers"));
export const Rooms = lazy(() => import("./Rooms"));
export const Procedures = lazy(() => import("./Procedures"));
export const Board = lazy(() => import("./Board"));
export const ConfigureBlockSchedule = lazy(() =>
  import("./ConfigureBlockSchedule")
);
export const EndOfDayConfirmation = lazy(() =>
  import("./EndOfDayConfirmation")
);
export const GoHomeList = lazy(() => import("./GoHomeList"));
export const GasShiftsCalendar = lazy(() => import("./GasShiftsCalendar"));
export const NotFound = lazy(() => import("./NotFound"));
export const Profile = lazy(() => import("./Profile"));
