import dayjs from "dayjs";
import moment from "moment";

const getTimeSlotsFor24hrs = () => {
  const slots = [];
  const slotDuration = 60; // minutes
  const currentYear = new Date().getFullYear();
  const startOfDay = new Date(currentYear, 0, 1, 0, 0, 0);
  const endOfDay = new Date(currentYear, 0, 1, 23, 59, 59);
  let id = 0;
  let currentSlotStart = startOfDay;
  while (currentSlotStart <= endOfDay) {
    const currentSlotEnd = new Date(
      currentSlotStart.getTime() + slotDuration * 60 * 1000
    );
    slots.push({ id: id++, start: currentSlotStart, end: currentSlotEnd });
    currentSlotStart = currentSlotEnd;
  }
  return slots;
};

const sanitizeValue = (value, defaultValue) => {
  if (value === "true") return true;
  if (value === "false") return false;
  if (value === "") return defaultValue;
  return value ?? defaultValue;
};

const areDatesSame = (start, end) => {
  start = moment(start);
  end = moment(end);

  if (start.isSame(end)) {
    return true;
  } else {
    return false;
  }
};

const getFirstLetters = (str = "") => {
  str = str?.toUpperCase();
  if (!str) return "";
  const initials = [];

  let i = 0;
  let len = str.length;

  while (i < len) {
    if (str[i] === "(") {
      // Skip the contents within parentheses
      while (i < len && str[i] !== ")") {
        i++;
      }
      i++; // Move past the closing parenthesis
      continue;
    }

    if (str[i] === " ") {
      i++;
      continue;
    }

    initials.push(str[i]);

    while (i < len && str[i] !== " " && str[i] !== "(") {
      i++;
    }
  }

  return initials.join("");
};

const materialColors = new Map([
  ["A", { backgroundColor: "#D32F2F", color: "#FFFFFF" }], // Darker Red
  ["B", { backgroundColor: "#C2185B", color: "#FFFFFF" }], // Darker Pink
  ["C", { backgroundColor: "#7B1FA2", color: "#FFFFFF" }], // Darker Purple
  ["D", { backgroundColor: "#512DA8", color: "#FFFFFF" }], // Darker Deep Purple
  ["E", { backgroundColor: "#303F9F", color: "#FFFFFF" }], // Darker Indigo
  ["F", { backgroundColor: "#1976D2", color: "#FFFFFF" }], // Darker Blue
  ["G", { backgroundColor: "#0288D1", color: "#FFFFFF" }], // Darker Light Blue
  ["H", { backgroundColor: "#0097A7", color: "#FFFFFF" }], // Darker Cyan
  ["I", { backgroundColor: "#00796B", color: "#FFFFFF" }], // Darker Teal
  ["J", { backgroundColor: "#388E3C", color: "#FFFFFF" }], // Darker Green
  ["K", { backgroundColor: "#689F38", color: "#FFFFFF" }], // Darker Light Green
  ["L", { backgroundColor: "#AFB42B", color: "#FFFFFF" }], // Darker Lime
  ["M", { backgroundColor: "#FBC02D", color: "#000000" }], // Darker Yellow, black text
  ["N", { backgroundColor: "#FFA000", color: "#000000" }], // Darker Amber, black text
  ["O", { backgroundColor: "#F57C00", color: "#000000" }], // Darker Orange, black text
  ["P", { backgroundColor: "#E64A19", color: "#FFFFFF" }], // Darker Deep Orange
  ["Q", { backgroundColor: "#5D4037", color: "#FFFFFF" }], // Darker Brown
  ["R", { backgroundColor: "#616161", color: "#FFFFFF" }], // Darker Grey
  ["S", { backgroundColor: "#455A64", color: "#FFFFFF" }], // Darker Blue Grey
  ["T", { backgroundColor: "#000000", color: "#FFFFFF" }], // Black
  ["U", { backgroundColor: "#757575", color: "#FFFFFF" }], // Darker Light Grey
  ["V", { backgroundColor: "#D32F2F", color: "#FFFFFF" }], // Darker Red variant
  ["W", { backgroundColor: "#1976D2", color: "#FFFFFF" }], // Darker Blue variant
  ["X", { backgroundColor: "#388E3C", color: "#FFFFFF" }], // Darker Green variant
  ["Y", { backgroundColor: "#F57C00", color: "#FFFFFF" }], // Darker Orange variant
  ["Z", { backgroundColor: "#5D4037", color: "#FFFFFF" }], // Darker Brown variant
]);

const detectTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

const getGroupOccurrencesByMonth = (dayOfWeek, year) => {
  const day = moment().utc().day(dayOfWeek).format("dddd");
  const occurrencesByMonth = {};

  // Loop through the last 12 months, starting from January of the current year
  for (let i = 0; i < 12; i++) {
    const month = moment()
      .utc()
      .set("year", year)
      .startOf("year")
      .add(i, "months");
    const monthName = month.format("MMMM");

    // Filter the days of the month that match the input day
    const matchingDays = [];
    const daysInMonth = month.daysInMonth();
    for (let j = 1; j <= daysInMonth; j++) {
      const date = moment(month).date(j);
      if (date.format("dddd") === day) {
        matchingDays.push(date);
      }
    }

    // Add the matching UTC strings to the corresponding month
    occurrencesByMonth[monthName] = matchingDays.map((date) =>
      date.toISOString()
    );
  }

  return occurrencesByMonth;
};

const convertToTimezone = (dateTime, timezone = "") => {
  return moment(dateTime).tz(timezone);
};

const splitName = (name = "") => {
  return name.split(" ");
};

const weekdays = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const snakeCaseToNormalString = (snakeCaseString = "") => {
  return snakeCaseString.split("_").join(" ");
};

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

const antdSearchByLabelProps = {
  optionFilterProp: "children",
  filterOption: (input, option) => (option?.label ?? "").includes(input),
  filterSort: (optionA, optionB) =>
    (optionA?.label ?? "")
      .toLowerCase()
      .localeCompare((optionB?.label ?? "").toLowerCase()),
};

const getNullFiltered = (obj) => {
  const filteredValues = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value) {
      filteredValues[key] = value;
    }
  });
  return filteredValues;
};

const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const disablePastDates = (current) => {
  const yesterday = dayjs().subtract(1, "day");
  const isInPast = current.isBefore(yesterday);
  return isInPast;
};

const addUniqueIdToObjects = (objects, keyName) => {
  // Simple counter to generate unique IDs
  let idCounter = 0;

  // Map each object to a new object with the added unique ID
  return objects.map((object) => ({
    ...object,
    [keyName]: `${keyName}-${idCounter++}`,
  }));
};

const inactiveShadow = "0px 0px 0px rgba(0,0,0,0.8)";

const createColorGenerator = () => {
  let colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
  ];

  const shuffleColors = () => {
    for (let i = colors.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [colors[i], colors[j]] = [colors[j], colors[i]];
    }
  };

  let index = 0;
  shuffleColors(); // Initial shuffle of colors

  return () => {
    const color = colors[index++];
    if (index >= colors.length) {
      index = 0;
      shuffleColors(); // Reshuffle when the end is reached
    }
    return color;
  };
};

// Usage

export {
  snakeCaseToNormalString,
  getTimeSlotsFor24hrs,
  materialColors,
  detectTouchDevice,
  areDatesSame,
  getGroupOccurrencesByMonth,
  days,
  weekdays,
  convertToTimezone,
  getFirstLetters,
  antdSearchByLabelProps,
  debounce,
  inactiveShadow,
  getNullFiltered,
  throttle,
  disablePastDates,
  splitName,
  sanitizeValue,
  addUniqueIdToObjects,
  createColorGenerator
};
