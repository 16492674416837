import { processDate } from "globalComponents/Schedular/helper";

const TimeSlotsWrapper = ({ timeSlots, handleTimeSlotRef }) => {
  return (
    <div className="time-slots-wrapper">
      {timeSlots.map((timeSlot, timeSlotIndex) => (
        <div
          className={`time-slot`}
          key={timeSlot.id}
          ref={(ref) => handleTimeSlotRef(ref, timeSlot.id, timeSlotIndex)}
        >
          <span>{processDate(timeSlot.time)}</span>
        </div>
      ))}
    </div>
  );
};

export default TimeSlotsWrapper;
