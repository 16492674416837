import { useDrop } from "react-dnd";

const useResource = ({ handleResourceDropCb, resource }) => {
  const [, drop] = useDrop(
    () => ({
      accept: "item-on-event",
      drop: (item) => {
        handleResourceDropCb({ ...resource, ...item });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [handleResourceDropCb]
  );

  return { drop };
};

export default useResource;
