export const getDirectionallyFilteredButtons = (buttons = []) => {
  const leftButtons = buttons.filter(
    ({ direction = "left" }) => direction === "left"
  );

  const rightButtons = buttons.filter(
    ({ direction = "left" }) => direction === "right"
  );

  return [leftButtons, rightButtons];
};
