import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import getRoutes from "./routes";

const RouterConfig = () => {
  const user = useSelector((state) => state.user);

  return <RouterProvider router={getRoutes(user.isAuthenticated)} />;
};

export default RouterConfig;
