import { LoadingOutlined } from "@ant-design/icons";
import { ConfigProvider, Spin } from "antd";
import antdVariables from "styles/antd/variables.json";

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: "2.5rem" }} spin />);

const AntdConfig = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: antdVariables,
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdConfig;
