import { SmileOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";

const CustomEmpty = ({ btnProps = null, text = "" }) => {
  return (
    <Empty
      style={{ margin: "10px 0" }}
      image={<SmileOutlined />}
      imageStyle={{ height: 15 }}
      description={<span className="subtle-text">{text}</span>}
    >
      {btnProps && <Button type={btnProps.type}>{btnProps.text}</Button>}
    </Empty>
  );
};

export default CustomEmpty;
