import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

const OPERATION_STATUS = {
  0: {
    text: "Unassigned",
    color: "red",
  },
  1: {
    text: "Canceled",
    color: "yellow",
  },
  2: {
    text: "Accepted",
    color: "green",
  },
};

const SCHEDULE_EVENTS = {
  ASSIGN_PROCEDURE: "assign_procedure",
  UNASSIGN_PROCEDURE: "unassign_procedure",
  ASSIGN_PROVIDER: "assign_provider",
  UNASSIGN_PROVIDER: "unassign_provider",
  PROCEDURE_COMPLETED: "proecudure_completed",
  PROCEDURE_CANCELLED: "procedure_cancelled",
  UNASSGINED_PROCEDURE_CANCELLED: "unassigned_procedure_cancelled",
  PROCEDURE_TIME_CHANGED: "procedure_time_changed",
  PROCEDURE_UPDATED: "procedure_updated",
  ASSIGN_PROVIDER_TO_ROOM: "assign_provider_to_room",
  UNASSIGN_PROVIDER_FROM_ROOM: "unassign_provider_to_room",
};

const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
  REFRESH_TOKEN: "refresh_token",
};

const TRIGGER_FROM = {
  CARD: "card",
  CELL: "cell",
};

const PROCEDURE_TYPES = {
  CANCELLED: "cancelled",
  UNASSIGNED: "unassigned",
  COMPLETED: "completed",
  ASSIGNED: "assigned",
  ADDON: "addon",
  QA_COMPLETED: "qa_completed",
  ALL: "all",
};

const PERMISSIONS = {
  CAN_VIEW_SCHEDULES: "CAN_VIEW_SCHEDULES",
  CAN_VIEW_ROOMS: "CAN_VIEW_ROOMS",
  CAN_VIEW_PROCEDURES: "CAN_VIEW_PROCEDURES",
};

const MODAL_MODE = {
  VIEW: "VIEW",
  ADD: "ADD",
  EDIT: "EDIT",
};

const PROVDER_STATUS_TAG = {
  active: (
    <Tag icon={<CheckCircleOutlined />} color="success" className="p-tag">
      Active
    </Tag>
  ),

  inactive: (
    <Tag icon={<ExclamationCircleOutlined />} color="success" className="p-tag">
      InActive
    </Tag>
  ),
};

const CASE_TAG_COLOR = {
  [PROCEDURE_TYPES.UNASSIGNED]: "warning",
  [PROCEDURE_TYPES.CANCELLED]: "error",
  [PROCEDURE_TYPES.COMPLETED]: "success",
};

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const DATE_FORMAT = "YYYY-MM-DD";

const DRAGGABLE_ITEMS = {
  PROVIDER: "PROVIDER",
  PROCEDURE: "PROCEDURE",
};

const BLOCK_TYPE = {
  SURGEON: "surgeon",
};

const DEFAULT_FACILITY = {
  id: "41d70593-5cef-4036-9b26-9ff7f55bb7ff",
  name: "Mountain Vista Medical Facility",
  code: "MVM",
};

const SHIFT_TYPE = {
  BASIC: "basic",
  EXTRA: "extra",
  OB: "OB",
  OR: "OR",
  CALL: "CALL",
  OFF: "OFF",
};

const AXIOS_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const DEFAULT_TIMEZONE = "America/Phoenix";

const AUTH_SOURCE = {
  LOGIN: "login",
  ME: "me",
};

const PRIMARY_COLOR = "#80adb4";

export {
  OPERATION_STATUS,
  SCHEDULE_EVENTS,
  TRIGGER_FROM,
  LOCAL_STORAGE_KEYS,
  PROCEDURE_TYPES,
  PERMISSIONS,
  PROVDER_STATUS_TAG,
  CASE_TAG_COLOR,
  DATE_TIME_FORMAT,
  MODAL_MODE,
  DRAGGABLE_ITEMS,
  DATE_FORMAT,
  BLOCK_TYPE,
  DEFAULT_FACILITY,
  SHIFT_TYPE,
  DEFAULT_TIMEZONE,
  AXIOS_METHODS,
  AUTH_SOURCE,
  PRIMARY_COLOR,
};
