import { DatePicker } from "antd";
import { DATE_FORMAT } from "constants/common";
import { useMemo } from "react";
import { FcCalendar } from "react-icons/fc";

const DateNavigator = ({ calendarDate, timezone, onDateChange }) => {
  const userFormattedDate = useMemo(
    () => calendarDate.format(DATE_FORMAT),
    [calendarDate]
  );

  return (
    <div className="date-navigator">
      <FcCalendar className="schedular-icon" />
      <div className="date-text">
        <h2 className="m-0 font-monstserrat white-space-no-wrap cursor-pointer">
          {userFormattedDate}
        </h2>
        <p className="m-0 subtle-text text-small">({timezone})</p>
      </div>
      <DatePicker
        allowClear={false}
        className="picker"
        value={calendarDate}
        onChange={onDateChange}
      />
    </div>
  );
};

export default DateNavigator;
