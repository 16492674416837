import { Button, Modal, Tag } from "antd";
import "./styles.scss";

const CustomModal = ({
  title,
  className = "",
  headerTags,
  width,
  footer = [],
  children,
  ...rest
}) => {
  return (
    <Modal
      title={<CustomModalHeader title={title} tags={headerTags} />}
      className={`custom-modal ${className} `}
      width={width || 800}
      footer={null}
      {...rest}
    >
      <div className="custom-modal-body">{children}</div>
      {footer.length > 0 && (
        <div className="custom-modal-footer">
          {footer.map(({ title, ...rest }) => (
            <Button {...rest} key={title}>
              {title}
            </Button>
          ))}
        </div>
      )}
    </Modal>
  );
};

const CustomModalHeader = ({ title = "", tags = [] }) => (
  <div className="custom-modal-header">
    <h2 className="font-monstserrat">{title}</h2>
    {tags.map(({ title, ...tag }, index) => (
      <Tag {...tag} key={index}>
        {title}
      </Tag>
    ))}
  </div>
);

export default CustomModal;
