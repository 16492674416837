import { Event } from "./components";
import "./styles.scss";

const Events = ({
  events,
  getDroppedEventStyles,
  setPreviousCalendarScrollOffset,
  handleResizeEvent,
  customComponent,
  handleDropOnEventCb,
  handleSelectEvent,
  resources,
}) =>
  events.map((event) => (
    <Event
      handleSelectEvent={handleSelectEvent}
      event={event}
      getDroppedEventStyles={getDroppedEventStyles}
      setPreviousCalendarScrollOffset={setPreviousCalendarScrollOffset}
      handleResizeEvent={handleResizeEvent}
      key={`${event?.id} ${resources?.length}`}
      customComponent={customComponent}
      handleDropOnEventCb={handleDropOnEventCb}
    />
  ));

export default Events;
