import { onMessageListener } from "@firebase/utils";
import { notification } from "antd";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const useFirebaseMessages = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const broadcast = useRef(new BroadcastChannel("background-message"));

  useEffect(() => {
    if (isAuthenticated) {
      let unsubscribeForeground, currentBroadcast;
      try {
        unsubscribeForeground = onMessageListener((payload) => {
          console.log({ payload });
          notification.info({
            message: payload?.notification.title,
            description: payload?.notification.body,
          });
        });

        currentBroadcast = broadcast.current;

        currentBroadcast.onmessage = (event) => {
          console.log(event?.data);
          notification.info({
            message: event.data?.notification?.title,
            description: event.data?.notification?.body,
          });
        };
      } catch (err) {
        console.error(err);
      }

      return () => {
        unsubscribeForeground?.();
        if (currentBroadcast) {
          currentBroadcast.close();
        }
      };
    } else {
      broadcast.current.close();
    }
  }, [isAuthenticated]);

  return null;
};

export default useFirebaseMessages;
