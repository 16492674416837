export const formRules = {
  "username-email": [
    {
      required: true,
      message: "Username or Email is required",
    },
    {
      // This pattern matches a simple username (alphanumeric, underscores, and dashes) or a basic email format
      pattern: /^(?:(?!.*\s)[a-zA-Z0-9_-]+|[^@\s]+@[^@\s]+\.[^@\s]+)$/,
      message:
        "Please enter a valid username (without spaces) or email address",
    },
  ],
  password: [
    {
      required: true,
      message: "Password is required",
    },
  ],
};
