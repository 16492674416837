import { Suspense } from "react";
import { Divider, Layout, Menu } from "antd";
import { Outlet } from "react-router-dom";

import { PageHeader } from "./components";
import { AppLoader, ProviderOnBoard } from "globalComponents";
import useDefaultLayout from "./useDefaultLayout";

const { Sider, Content } = Layout;

const DefaultLayout = () => {
  const {
    user,
    collapsed,
    setCollapsed,
    colorBgContainer,
    screens,
    handleMenuItemClick,
    currentActivePath,
    processedNavItems,
    isTourRequired,
  } = useDefaultLayout();

  return (
    <>
      <ProviderOnBoard user={user} isTourRequired={isTourRequired} />
      <Layout className="default-layout-wrapper">
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          width={170}
          className="default-layout-sider"
          collapsedWidth={screens.sm ? 50 : 0}
          trigger={null}
        >
          <div className={"logo"} />

          <Divider className="logo-divider" />

          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            items={processedNavItems}
            onClick={handleMenuItemClick}
            selectedKeys={[currentActivePath]}
            className="sider-menu"
          />
        </Sider>
        <Layout
          className={`default-layout-inner ${
            currentActivePath === "/board" ? "board" : ""
          }`}
        >
          <PageHeader
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            colorBgContainer={colorBgContainer}
          />
          <Content className={"default-layout-content"}>
            <Suspense fallback={<AppLoader loading lazy />}>
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DefaultLayout;
