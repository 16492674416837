const CountCard = ({ title = "", count = 0, description = "" }) => (
  <div className="count-card">
    <div className="count-left-side">
      <span className="count-card-title subtle-text">{title}</span>
      <span className="count-card-desc subtle-text">{description}</span>
    </div>
    <span className="count-card-number">{count}</span>
  </div>
);

export default CountCard;
