import { usePreview } from "react-dnd-preview";

const TouchDndPreview = () => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { item, style } = preview;

  const label = item?.name || item?.title || item?.providerName;

  return (
    <div className="touch-dnd-preview" style={style}>
      {label}
    </div>
  );
};

export default TouchDndPreview;
