const timeInterval = 60;

const startHour = new Date();
startHour.setHours(9);
startHour.setMinutes(0);

const endHour = new Date();
endHour.setHours(19);
endHour.setMinutes(0);

export { timeInterval, startHour, endHour };
