import {
  CellsWrapper,
  Events,
  ResourceHeader,
  TimeSlotsWrapper,
  TouchDndPreview,
} from "./components";
import "./styles.scss";
import useSchedular from "./useSchedular";

const Schedular = ({
  events: unprocessedEvents,
  resources,
  handleDropFromOutsideCb,
  handleDropOnEventCb,
  handleInnerDropCb,
  handleResizeEventCb,
  handleResourceDropCb,
  calendarDate,
  components,
  handleSelectSlot,
  handleSelectEvent,
  timeInterval = 60,
  allowSlotOverlap = false,
  timezone,
  startHourInView,
  autoScroll = false,
  allowDropOnSchedular,
}) => {
  const {
    timeSlots,
    handleCellRef,
    handleColumnRef,
    handleTimeSlotRef,
    handleOverlayDrop,
    getDroppedEventStyles,
    isCalendarRendered,
    setIsCalendarRendered,
    setPreviousCalendarScrollOffset,
    handleResizeEvent,
    events,
    isTouchDevice,
    calendarContainerRef,
  } = useSchedular({
    resources,
    unprocessedEvents,
    handleDropFromOutsideCb,
    handleDropOnEventCb,
    handleInnerDropCb,
    handleResizeEventCb,
    calendarDate,
    allowSlotOverlap,
    timeInterval,
    timezone,
    startHourInView,
    autoScroll,
  });

  return (
    <div className="schedular" ref={calendarContainerRef}>
      <ResourceHeader
        resources={resources}
        customComponent={components?.resourceHeader}
        allowDropOnSchedular={allowDropOnSchedular}
        handleResourceDropCb={handleResourceDropCb}
      />

      <div className="schedular-content">
        {isTouchDevice && <TouchDndPreview />}
        <div className="schedular-content-inner">
          <TimeSlotsWrapper
            timeSlots={timeSlots}
            handleTimeSlotRef={handleTimeSlotRef}
          />
          <CellsWrapper
            events={events}
            resources={resources}
            timeSlots={timeSlots}
            handleCellRef={handleCellRef}
            handleColumnRef={handleColumnRef}
            setIsCalendarRendered={setIsCalendarRendered}
            isCalendarRendered={isCalendarRendered}
            handleOverlayDrop={handleOverlayDrop}
            handleSelectSlot={handleSelectSlot}
            allowDropOnSchedular={allowDropOnSchedular}
          >
            {isCalendarRendered && (
              <Events
                resources={resources}
                customComponent={components?.event}
                events={events}
                getDroppedEventStyles={getDroppedEventStyles}
                setPreviousCalendarScrollOffset={
                  setPreviousCalendarScrollOffset
                }
                handleDropOnEventCb={handleDropOnEventCb}
                handleResizeEvent={handleResizeEvent}
                handleSelectEvent={handleSelectEvent}
              />
            )}
          </CellsWrapper>
        </div>
      </div>
    </div>
  );
};

export default Schedular;
