import { Grid, theme } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import navItems from "./navItems";
import { useAbility } from "@casl/react";
import { AbilityContext } from "contexts/can";
import { DEFAULT_ACTIONS } from "abilities/definitions";
import { useSelector } from "react-redux";
import { ROLES } from "abilities/definitions";

const { useBreakpoint } = Grid;

const useDefaultLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const user = useSelector((state) => state.user.user);
  const [currentActivePath, setCurrentActivePath] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/board"
      : location.pathname
  );
  const screens = useBreakpoint();

  useEffect(() => {
    if (location) {
      if (currentActivePath !== location.pathname) {
        setCurrentActivePath(location.pathname);
      }
    }
  }, [location, currentActivePath]);

  const handleMenuItemClick = (e) => {
    setCurrentActivePath(e.key);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const processedNavItems = useMemo(
    () =>
      navItems.filter((navItem) =>
        ability.can(DEFAULT_ACTIONS.VIEW, navItem.module)
      ),
    [ability]
  );

  const isTourRequired = useMemo(() => {
    const tourNotRequiredRoutes = [
      "/gas-open-shifts-calendar",
      "/gas-shifts-annual-calendar",
    ];

    return (
      user?.newRegistration &&
      !tourNotRequiredRoutes.includes(currentActivePath) &&
      user?.role === ROLES.PROVIDER
    );
  }, [currentActivePath, user?.newRegistration, user.role]);

  return {
    user,
    collapsed,
    setCollapsed,
    colorBgContainer,
    screens,
    handleMenuItemClick,
    currentActivePath,
    processedNavItems,
    isTourRequired,
  };
};

export default useDefaultLayout;
