import { AnimatePresence, motion } from "framer-motion";
import "./styles.scss";
import { LoadingOutlined } from "@ant-design/icons";

const AppLoader = ({
  appEntry = false,
  overlay,
  loading,
  lazy,
  transparent = true,
}) => {
  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`loader-wrapper ${lazy ? "lazy" : ""} ${
            transparent ? "subloader-wrapper" : "app-loader"
          } ${overlay ? "overlay" : ""}`}
        >
          {appEntry ? (
            <div className="app-entry-wrapper">
              <div className="app-entry-logo" />
              <div className={`antd-spin ${appEntry ? "entry" : ""}`}>
                <LoadingOutlined spin />
              </div>
            </div>
          ) : (
            <LoadingOutlined spin className="antd-spin" />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AppLoader;
