import { REIGSTER } from "constants/apiEndpoints";
import { AXIOS_METHODS } from "constants/common";
import { useAxios } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useSignup = () => {
  const navigate = useNavigate();

  const { triggerApi: triggerRegisterApi, success: registerApiSuccess } =
    useAxios({
      method: AXIOS_METHODS.POST,
      url: REIGSTER,
      onInit: false,
      showSuccessMessage: true,
    });

  useEffect(() => {
    if (registerApiSuccess) {
      navigate("/auth/login");
    }
  }, [registerApiSuccess, navigate]);

  const handleNavigation = (to) => {
    navigate(to);
  };

  const handleRegistration = (values) => {
    triggerRegisterApi({
      data: values,
    });
  };

  return { handleNavigation, handleRegistration };
};

export default useSignup;
