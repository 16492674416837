import { Resizable } from "react-resizable";
import useEvent from "./useEvent";
import { memo } from "react";

const Event = ({
  event,
  getDroppedEventStyles,
  setPreviousCalendarScrollOffset,
  handleResizeEvent,
  customComponent = {},
  handleDropOnEventCb,
  handleSelectEvent = null,
}) => {
  const {
    styles,
    height,
    isDragging,
    setDisableResize,
    handleResizeStop,
    onResize,
    drop,
    drag,
  } = useEvent({
    event,
    getDroppedEventStyles,
    setPreviousCalendarScrollOffset,
    handleResizeEvent,
    handleDropOnEventCb,
  });

  const { Component, props = {} } = customComponent;
  const { draggable = true, resizeable = true, dropable = true } = event;

  const cardInner = (
    <div className="ec-card-inner" ref={dropable ? drop : null}>
      {Component ? (
        <Component
          onEventClick={() => handleSelectEvent(event)}
          event={event}
          {...props}
        />
      ) : (
        event?.title
      )}
    </div>
  );

  return (
    <div
      id={`event-card-${event.id}`}
      style={{ height, ...styles }}
      className={`event-card ${draggable && isDragging ? "is-dragging" : ""} ${
        event.overlapCount > 0 ? "overlapping" : ""
      }`}
      ref={draggable ? drag : null}
    >
      {resizeable ? (
        <Resizable
          onResize={onResize}
          onResizeStart={() => {
            setDisableResize(true);
          }}
          onResizeStop={handleResizeStop}
          height={height}
          width={styles.width}
          axis="y"
        >
          {cardInner}
        </Resizable>
      ) : (
        cardInner
      )}
    </div>
  );
};

export default memo(Event);
