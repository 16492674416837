import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "styles/scss/main.scss";
import { AntdConfig } from "hoc";
import { Provider } from "react-redux";
import store from "services/redux/store";
import { TouchBackend } from "react-dnd-touch-backend";
import { detectTouchDevice } from "./utils";
import { AbilityContext } from "contexts/can";
import { createMongoAbility } from "@casl/ability";
import { SocketProvider } from "contexts/socket";
import { TourProvider } from "contexts/tour";

const root = ReactDOM.createRoot(document.getElementById("root"));
const isTouchDevice = detectTouchDevice();

const dndBackend = isTouchDevice ? TouchBackend : HTML5Backend;
const backendOptions = isTouchDevice
  ? { enableMouseEvents: false, delayTouchStart: 300 }
  : {};

const ability = new createMongoAbility();

root.render(
  <React.StrictMode>
    <AbilityContext.Provider value={ability}>
      <TourProvider>
        <SocketProvider>
          <AntdConfig>
            <Provider store={store}>
              <DndProvider backend={dndBackend} options={backendOptions}>
                <App />
              </DndProvider>
            </Provider>
          </AntdConfig>
        </SocketProvider>
      </TourProvider>
    </AbilityContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
