import { createBrowserRouter, Navigate } from "react-router-dom";

import { MODULES } from "abilities/definitions";
import { WithHeaderAndPermissions } from "hoc";
import { AuthLayout, DefaultLayout } from "layouts";
import {
  Board,
  ConfigureBlockSchedule,
  EndOfDayConfirmation,
  GasShiftsCalendar,
  GoHomeList,
  NotFound,
  Procedures,
  Profile,
} from "../pages/app";
import { Login, Signup } from "../pages/auth";
import { GAS_SHIFT_PAGE_MODE } from "pages/app/GasShiftsCalendar/constants";

const getRoutes = (isAuthenticated = true) =>
  createBrowserRouter([
    {
      path: "auth",
      element: isAuthenticated ? <Navigate to={"/"} replace /> : <AuthLayout />,
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <Signup />,
        },
      ],
    },
    {
      path: "",
      element: isAuthenticated ? (
        <DefaultLayout />
      ) : (
        <Navigate to={"/auth/login"} replace />
      ),
      children: [
        {
          index: true,
          element: (
            <WithHeaderAndPermissions
              Component={Board}
              pageTitle="Board"
              module={MODULES.BOARD}
            />
          ),
        },
        {
          path: "procedures",
          element: (
            <WithHeaderAndPermissions
              Component={Procedures}
              pageTitle={"Cases"}
              module={MODULES.PROCEDURES}
            />
          ),
        },
        {
          path: "end-of-day",
          element: (
            <WithHeaderAndPermissions
              Component={EndOfDayConfirmation}
              pageTitle={"End Of Day Confirmation"}
              module={MODULES.EOD_CONFIRMATION}
            />
          ),
        },
        {
          path: "block-schedule/configure",
          element: (
            <WithHeaderAndPermissions
              Component={ConfigureBlockSchedule}
              pageTitle={"Block Schedule"}
              module={MODULES.BLOCK_SCHEDULES}
            />
          ),
        },
        {
          path: "go-home-list",
          element: (
            <WithHeaderAndPermissions
              Component={GoHomeList}
              pageTitle="Go Home List"
              module={MODULES.GO_HOME_LIST}
            />
          ),
        },
        {
          path: "gas-shifts-calendar",
          element: (
            <WithHeaderAndPermissions
              Component={GasShiftsCalendar}
              pageTitle="GAS Shifts"
              module={MODULES.GAS_SHIFTS_CALENDAR}
              elementProps={{ mode: GAS_SHIFT_PAGE_MODE.REGULAR }}
            />
          ),
        },
        {
          path: "gas-shifts-annual-calendar",
          element: (
            <WithHeaderAndPermissions
              Component={GasShiftsCalendar}
              pageTitle="Annual Shifts"
              module={MODULES.GAS_SHIFTS_CALENDAR}
              elementProps={{ mode: GAS_SHIFT_PAGE_MODE.ANNUAL }}
            />
          ),
        },
        {
          path: "gas-open-shifts-calendar",
          element: (
            <WithHeaderAndPermissions
              Component={GasShiftsCalendar}
              pageTitle="Open Shifts"
              module={MODULES.GAS_SHIFTS_CALENDAR}
              elementProps={{ mode: GAS_SHIFT_PAGE_MODE.OPEN }}
            />
          ),
        },

        {
          path: "profile",
          element: (
            <WithHeaderAndPermissions
              Component={Profile}
              pageTitle="Profile"
              module={MODULES.GAS_SHIFTS_CALENDAR}
            />
          ),
        },

        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

export default getRoutes;
