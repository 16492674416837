import { SHIFT_TYPE } from "constants/common";

export const CALENDAR_VIEW = {
  MONTH: "month",
  WEEK: "week",
};

export const SHIFT_TAGS = {
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned",
  VACATION: "vacation",
  ALL: "all",
};

export const PROVIDER_VACATION_TYPES = {
  OFF_24_HOUR_CALL: "off_24h_after_call",
  OFF_24_HOUR_AFTER_OB: "off_24h_after_ob",
  VACATION: "vacation",
};

export const SHIFT_SELECTION_FOR = {
  SWAP: "swap",
  ASSIGN: "assign",
  TRANSFER: "transfer",
};

export const SORT_SHIFT_BY = {
  DURATION: "duration",
  TYPE: "type",
  FACILITY: "facility",
};

export const GAS_SHIFT_PAGE_MODE = {
  REGULAR: "regular",
  ANNUAL: "annual",
  OPEN: "open",
};

export const TOUR_REFS = {
  SETTINGS_ICON: "settingsIcon",
  TRACK_SELECTION_ICON: "trackSelectionIcon",
  CALENDAR_MONTH_VIEW: "calendarMonthView",
  CALENDAR_WEEK_VIEW: "calendarWeekView",
};

export const SHIFT_BADGE_COLORS = {
  [SHIFT_TYPE.OB]: "#800080", // Purple
  [SHIFT_TYPE.CALL]: "#FF0000", // Red
  [SHIFT_TYPE.OFF]: "#008000", // Green
};
