import { useDispatch } from "react-redux";
import { message } from "antd";
import { animate, useMotionValue } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import axios from "services/api/axiosInstance";
import { setDashboardHeaderProps } from "services/redux/slices/layout";
import { inactiveShadow } from "utils";

dayjs.extend(utc);
dayjs.extend(timezone);

export const useAxios = ({
  onInit = true,
  showSuccessMessage = false,
  ...axiosParams
}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const isMountRef = useRef(false);
  const controllerRef = useRef(new AbortController());
  const cancelRequest = useCallback(() => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = new AbortController();
    }
  }, []);

  const apiCall = useCallback(
    async (params, restParams = {}) => {
      try {
        setLoading(true);
        setSuccess(null);
        setResponse(null);
        const { url } = params;
        if (typeof url !== "string") {
          const promises = url?.map((url) =>
            axios.request({
              url,
              ...params,
              ...restParams,
              signal: controllerRef.current.signal,
            })
          );

          const results = await Promise.all(promises);
          setResponse(() => results.map((result) => result?.data?.data));
        } else {
          const result = await axios.request({
            ...params,
            ...restParams,
            signal: controllerRef.current.signal,
          });

          setResponse(result.data?.data);
          setSuccess(result?.data?.success);
          if (showSuccessMessage) {
            message.success(result?.data?.message);
          }
          if (result?.data?.success !== true) {
            message.warning(result?.data?.message);
          }
          return result?.data;
        }
      } catch (err) {
        setSuccess(false);
        setError(err);
        setResponse(null);

        if (err.name !== "CanceledError") {
          message.error(err?.response?.data?.message || "Something went wrong");
          console.error(err);
        }
        return err;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const triggerApi = useCallback(
    (restParams) => {
      return apiCall(axiosParams, restParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiCall]
  );

  useEffect(() => {
    if (!isMountRef.current && process.env.NODE_ENV === "development") {
      isMountRef.current = true;
      return;
    }
    onInit && apiCall(axiosParams);

    return () => cancelRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInit]);

  return {
    cancelRequest,
    response,
    error,
    success,
    loading,
    triggerApi,
    setResponse,
  };
};

export const useDashboardHeaderProps = (dashboardHeaderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDashboardHeaderProps(dashboardHeaderProps));
  }, [dispatch, dashboardHeaderProps]);
};

export const useDivHeight = (divRef) => {
  const [size, setSize] = useState({ height: 0 });

  useEffect(() => {
    if (divRef.current) {
      const handleResize = () =>
        setSize({
          height: divRef.current.offsetHeight,
        });
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [divRef]);

  return size;
};

export function useLaterEffect(callback, dependencies = []) {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export function useRaisedShadow(value) {
  const boxShadow = useMotionValue(inactiveShadow);

  useEffect(() => {
    let isActive = false;
    value.onChange((latest) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, "5px 5px 10px rgba(0,0,0,0.3)");
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, inactiveShadow);
        }
      }
    });
  }, [value, boxShadow]);

  return boxShadow;
}

export const usePrevious = (value) => {
  const previousVal = useRef(null);

  useEffect(() => {
    previousVal.current = value;
  }, [value]);

  return previousVal.current;
};

export const useNetworkState = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return { isOnline };
};

export const useCurrentTimeDifference = (
  timezone,
  referenceTime,
  format = "HH:mm:ss"
) => {
  const [formattedTimeDifference, setFormattedTimeDifference] = useState("");

  useEffect(() => {
    let intervalId = null; // Initialize intervalId for later reference

    const update = () => {
      const now = dayjs().tz(timezone);
      const diff = referenceTime.diff(now); // Calculate the difference in milliseconds

      // Check if the difference is zero
      if (diff === 0) {
        setFormattedTimeDifference("00:00:00"); // Set the formatted time difference to "00:00:00"
        if (intervalId) {
          clearInterval(intervalId); // Clear the interval if the diff is 0 to stop updates
          intervalId = null; // Ensure the interval ID is reset to prevent memory leaks
        }
      } else {
        // If the difference is not zero, continue updating the formatted time difference
        const formattedDiff = dayjs()
          .startOf("day")
          .add(diff, "millisecond")
          .format(format);
        setFormattedTimeDifference(formattedDiff);
      }
    };

    update(); // Update immediately on mount
    if (intervalId === null) {
      // Check to ensure no interval is set before creating a new one
      intervalId = setInterval(update, 1000); // Update every second
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Cleanup on unmount
      }
    };
  }, [timezone, referenceTime, format]); // Dependency array includes the parameters to re-run effect if they change

  return formattedTimeDifference;
};
