import { AbilityBuilder } from "@casl/ability";
import { ROLES_PERMISSIONS } from "./definitions";

export const defineAbilityFor = (ability, role) => {
  const { can, rules } = new AbilityBuilder();

  const permissions = ROLES_PERMISSIONS[role];
  if (permissions) {
    for (const [module, actions] of Object.entries(permissions)) {
      for (const action of actions) {
        can(action, module);
      }
    }
  }

  ability.update(rules);
};
