import momentTz from "moment-timezone";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDndScrolling } from "react-dnd-scrolling";
import {
  changeMinutes,
  detectTouchDevice,
  generateTimeSlots,
  getTimeBounds,
} from "./helper";

const useSchedular = ({
  unprocessedEvents,
  resources,
  handleDropFromOutsideCb,
  handleInnerDropCb,
  handleResizeEventCb,
  calendarDate,
  allowSlotOverlap,
  timeInterval,
  timezone,
  startHourInView,
  autoScroll,
}) => {
  // states
  const [isCalendarRendered, setIsCalendarRendered] = useState(false);
  const [events, setEvents] = useState([]);

  // refs
  const previousCalendarContainerScrollOffset = useRef({
    top: 0,
    left: 0,
  });
  const calendarContainerRef = useRef();
  const timeSlotNodeProps = useRef([]);
  const columnNodeProps = useRef([]);
  const cellNodeProps = useRef([]);

  // memos
  const timeSlots = useMemo(
    () => generateTimeSlots(calendarDate, timeInterval, timezone),
    [calendarDate, timeInterval, timezone]
  );

  const timeBounds = useMemo(
    () => getTimeBounds(calendarDate, timezone),
    [calendarDate, timezone]
  );

  const isTouchDevice = useMemo(detectTouchDevice, []);

  // functions

  const getCellSlotWidth = useCallback(() => {
    if (cellNodeProps?.current?.[0]?.ref) {
      return cellNodeProps.current[0].ref.getBoundingClientRect().width;
    }
    return 0;
  }, []);

  const getEventHeight = useCallback(
    (start, end) => {
      const startClone = start.clone();
      const endClone = end.clone();
      const timeSlotHeight = cellNodeProps.current[0].cellHeight;
      const dateDifferenceInMins = endClone.diff(startClone, "minutes");
      const minuteInPixels = timeSlotHeight / timeInterval;
      return minuteInPixels * dateDifferenceInMins;
    },
    [timeInterval]
  );

  const getDroppedEventStyles = useCallback(
    (item) => {
      const { start, end, resourceId } = item;
      const { startHour } = timeBounds;
      const startHourMoment = momentTz.tz(startHour, timezone);

      const timeSlotHeight = cellNodeProps.current[0].cellHeight;
      const minuteInPixels = timeSlotHeight / timeInterval;
      const top =
        (start.hours() - startHourMoment.hours()) * timeSlotHeight +
        start.minutes() * minuteInPixels;
      const height = getEventHeight(start, end);

      const currentResourseIndex = resources.findIndex(
        (resource) => resource.id === resourceId
      );

      const cellWidth = getCellSlotWidth();

      const blockWidth =
        item.overlapCount > 0 ? cellWidth / (item.overlapCount + 1) : cellWidth;
      let left = currentResourseIndex * cellWidth + currentResourseIndex;
      if (item.overlapIndex > 0) {
        left = left + blockWidth * (item.overlapIndex - 1);
      }

      return {
        top,
        left,
        height,
        width: blockWidth,
      };
    },
    [
      resources,
      timeBounds,
      getEventHeight,
      timeInterval,
      timezone,
      getCellSlotWidth,
    ]
  );

  const adjustEventsStyle = useCallback(() => {
    events.forEach((event) => {
      const eventElement = document.getElementById(`event-card-${event.id}`);
      const { top, left, width, height } = getDroppedEventStyles(event);

      eventElement.style.width = `${width}px`;
      eventElement.style.height = `${height}px`;
      eventElement.style.top = `${top}px`;
      eventElement.style.left = `${left}px`;
    });
  }, [events, getDroppedEventStyles]);

  const updateCalendarCoordinates = useCallback((e) => {
    const updatedCoordinates = cellNodeProps.current.map((cell) => {
      const ref = cell.ref;
      const cellRect = ref.getBoundingClientRect();
      return {
        ...cell,
        cellHeight: cellRect.height,
        coordinates: {
          x1: cellRect.left,
          y1: cellRect.top,
          x2: cellRect.right,
          y2: cellRect.bottom,
        },
      };
    });

    const updatedColumnProps = columnNodeProps.current.map((column) => {
      const ref = column.ref;
      const columnRect = ref.getBoundingClientRect();
      return {
        ...column,
        coordinates: {
          x1: columnRect.left,
          y1: columnRect.top,
          x2: columnRect.right,
          y2: columnRect.bottom,
        },
      };
    });

    const updatedTimeSlotProps = timeSlotNodeProps.current.map((timeSlot) => {
      const ref = timeSlot.ref;
      const timeSlotRect = ref.getBoundingClientRect();

      return {
        ...timeSlot,
        coordinates: {
          x1: timeSlotRect.left,
          y1: timeSlotRect.top,
          x2: timeSlotRect.right,
          y2: timeSlotRect.bottom,
        },
      };
    });

    cellNodeProps.current = updatedCoordinates;
    columnNodeProps.current = updatedColumnProps;
    timeSlotNodeProps.current = updatedTimeSlotProps;
  }, []);

  const handleCellRef = useCallback(
    (ref, resourceId, timeSlotId, cellIndex) => {
      if (ref) {
        const cellRect = ref.getBoundingClientRect();
        const cellHeight = cellRect.height;

        cellNodeProps.current[cellIndex] = {
          ref,
          cellHeight,
          resourceId,
          timeSlotId,
          coordinates: {
            x1: cellRect.left,
            y1: cellRect.top,
            x2: cellRect.right,
            y2: cellRect.bottom,
          },
        };
      }
    },
    []
  );

  const detectOverlaping = useCallback(
    ({ newStart, newEnd, newResourceId, id, blockType }) =>
      events?.some(
        (event) =>
          event.blockType === blockType &&
          event.id !== id &&
          event?.resourceId === newResourceId &&
          newStart.isBefore(event.end) &&
          newEnd.isAfter(event.start)
      ),
    [events]
  );

  const findResourceColumnByCoordinates = useCallback(
    ({ x, item }) => {
      const { resourceId } = item;

      const resourceIndex = resources.findIndex(
        (resource) => resource.id === resourceId
      );

      const cellWidth = getCellSlotWidth();
      const initialLeft = resourceIndex * cellWidth + resourceIndex;

      const newLeft = initialLeft + x;

      const newCellProportion = newLeft / cellWidth;

      const beforeDecimal = Math.trunc(newCellProportion);
      const afterDecimal = parseInt(
        Math.abs(newCellProportion % 1)
          .toFixed(2)
          .substring(2)
      );

      let newResourceId;

      if (afterDecimal > 50) {
        newResourceId = resources[beforeDecimal + 1].id;
      } else {
        newResourceId = resources[beforeDecimal].id;
      }

      return newResourceId;
    },
    [resources, getCellSlotWidth]
  );
  const handleInnerDrop = useCallback(
    ({ item, differenceFromInitialOffset }) => {
      const timeSlotHeight = cellNodeProps.current[0].cellHeight;

      let { x, y } = differenceFromInitialOffset;

      const currrentContainerScrollOffsetTop =
        calendarContainerRef.current.scrollTop;

      const currrentContainerScrollOffsetLeft =
        calendarContainerRef.current.scrollLeft;

      if (
        currrentContainerScrollOffsetTop !==
        previousCalendarContainerScrollOffset.current.top
      ) {
        y =
          y +
          (currrentContainerScrollOffsetTop -
            previousCalendarContainerScrollOffset.current.top);
      }

      if (
        currrentContainerScrollOffsetLeft !==
        previousCalendarContainerScrollOffset.current.left
      ) {
        x =
          x +
          (currrentContainerScrollOffsetLeft -
            previousCalendarContainerScrollOffset.current.left);
      }

      const changedMinutes = (y / timeSlotHeight) * timeInterval;

      const newResourceId = findResourceColumnByCoordinates({
        x,
        item,
      });

      previousCalendarContainerScrollOffset.current.top =
        calendarContainerRef.current.scrollTop;

      previousCalendarContainerScrollOffset.current.left =
        calendarContainerRef.current.scrollLeft;

      const event = events.find((event) => event.id === item.id);

      if (event) {
        const { newStart, newEnd } = changeMinutes(
          event,
          changedMinutes,
          timezone,
          calendarDate
        );

        const updatedEvent = {
          start: newStart,
          end: newEnd,
          resourceId: newResourceId,
        };

        if (allowSlotOverlap) {
          handleInnerDropCb({
            ...event,
            ...updatedEvent,
          });
        } else {
          const isOverlaping = detectOverlaping({
            newStart,
            newEnd,
            newResourceId,
            id: item?.id,
            blockType: item?.blockType,
          });
          if (isOverlaping) return;
          else
            handleInnerDropCb({
              ...event,
              ...updatedEvent,
            });
        }
      }
    },
    [
      allowSlotOverlap,
      calendarDate,
      detectOverlaping,
      events,
      handleInnerDropCb,
      findResourceColumnByCoordinates,
      timeInterval,
      timezone,
    ]
  );

  const handleDropFromOutside = useCallback(
    ({ item, clientOffset, sourceClientOffset }) => {
      const { x } = clientOffset;

      const foundedColumnIndex = columnNodeProps.current.findIndex(
        (columnNodeProp) => {
          const { x1, x2 } = columnNodeProp.coordinates;
          return x > x1 && x < x2;
        }
      );

      const foundedColumn = columnNodeProps.current[foundedColumnIndex];
      const yAxisScrolledValue = calendarContainerRef.current.scrollTop;

      if (foundedColumn) {
        const { coordinates } = foundedColumn;

        const columnStartPixel = yAxisScrolledValue + coordinates.y1;

        let droppedItemRelativeY =
          sourceClientOffset.y - columnStartPixel + yAxisScrolledValue;

        droppedItemRelativeY =
          droppedItemRelativeY > 0 ? droppedItemRelativeY : 0;

        const timeSlotHeight = cellNodeProps.current[0].cellHeight;
        const minuteInPixels = timeSlotHeight / timeInterval;

        const start = timeBounds.startHour.clone();
        const dayEndHour = timeBounds.endHour.clone();
        const minutesToAdd = droppedItemRelativeY / minuteInPixels;
        start.add(minutesToAdd, "minutes");
        let end = start.clone();
        end.add(item?.duration || timeInterval, "minutes");

        const resourceId = resources[foundedColumnIndex].id;

        const extraExceedingTimeInSeconds = end
          .clone()
          .diff(dayEndHour, "seconds");

        console.log({ extraExceedingTimeInSeconds });

        if (extraExceedingTimeInSeconds > 0) {
          start.subtract(extraExceedingTimeInSeconds, "seconds");
          end = dayEndHour.clone();
        }

        if (allowSlotOverlap) {
          handleDropFromOutsideCb({
            start: start.utc().format(),
            end: end.utc().format(),
            resourceId,
            ...item,
          });
        } else {
          const isOverlaping = detectOverlaping({
            newStart: start,
            newEnd: end,
            newResourceId: resourceId,
            id: item?.id,
          });
          if (isOverlaping) return;
          else
            handleDropFromOutsideCb({
              start: start.utc().format(),
              end: end.utc().format(),
              resourceId,
              ...item,
            });
        }

        return;
      }
    },
    [
      allowSlotOverlap,
      detectOverlaping,
      handleDropFromOutsideCb,
      timeInterval,
      resources,
      timeBounds,
    ]
  );
  const handleOverlayDrop = useCallback(
    ({
      differenceFromInitialOffset,
      item,
      sourceClientOffset,
      clientOffset,
    }) => {
      const { fromOutside = false, ...rest } = item;
      if (fromOutside) {
        handleDropFromOutside({
          clientOffset,
          differenceFromInitialOffset,
          sourceClientOffset,
          item: rest,
        });
      } else {
        handleInnerDrop({ differenceFromInitialOffset, item });
      }
    },
    [handleDropFromOutside, handleInnerDrop]
  );

  const handleTimeSlotRef = useCallback((ref, timeSlotId, timeSlotIndex) => {
    if (ref) {
      const timeSlotRect = ref.getBoundingClientRect();
      const timeSlotHeight = timeSlotRect.height;
      timeSlotNodeProps.current[timeSlotIndex] = {
        ref,
        timeSlotHeight,
        timeSlotId,
        coordinates: {
          x1: timeSlotRect.left,
          y1: timeSlotRect.top,
          x2: timeSlotRect.right,
          y2: timeSlotRect.bottom,
        },
      };
    }
  }, []);

  const handleColumnRef = (ref, resourceId, resourceIndex) => {
    if (ref) {
      const columnRect = ref.getBoundingClientRect();
      columnNodeProps.current[resourceIndex] = {
        ref,
        resourceId,
        coordinates: {
          x1: columnRect.left,
          y1: columnRect.top,
          x2: columnRect.right,
          y2: columnRect.bottom,
        },
      };
    }
  };

  const setPreviousCalendarScrollOffset = useCallback(() => {
    previousCalendarContainerScrollOffset.current.top =
      calendarContainerRef.current.scrollTop;

    previousCalendarContainerScrollOffset.current.left =
      calendarContainerRef.current.scrollLeft;
  }, []);

  const handleResizeEvent = useCallback(
    ({ item, height }) => {
      const { start, end } = item;
      const currentHeight = height;
      const timeSlotHeight = cellNodeProps.current[0].cellHeight;
      const previousHeight = getEventHeight(start, end);
      const changeInHeight = currentHeight - previousHeight;
      const minuteInPixels = timeSlotHeight / timeInterval;
      const changeInMinutes = changeInHeight / minuteInPixels;
      const event = events.find((event) => event.id === item.id);
      if (event) {
        const { newEnd } = changeMinutes(event, changeInMinutes, timezone);
        handleResizeEventCb({
          ...event,
          start: event.start,
          end: newEnd,
        });
      }
    },
    [events, getEventHeight, handleResizeEventCb, timeInterval, timezone]
  );

  useEffect(() => {
    const { startHour: startOfDay, endHour: endOfDay } = timeBounds;

    let processedEvents = [];

    // Process each event
    for (let event of unprocessedEvents) {
      const eventStart = momentTz.tz(event.start, timezone);
      let eventEnd = momentTz.tz(event.end, timezone);

      if (
        eventStart.isBetween(startOfDay, endOfDay, null, "[]") &&
        eventEnd.isBetween(startOfDay, endOfDay, null, "[]")
      ) {
        let exceedingTimeAfterToday = eventEnd
          .clone()
          .diff(endOfDay, "minutes");
        if (exceedingTimeAfterToday > 0) {
          eventEnd = endOfDay.clone();
        }

        processedEvents.push({
          ...event,
          start: eventStart,
          end: eventEnd,
          exceedingTimeAfterToday,
          overlapCount: 0,
          overlapIndex: 0,
        });
      }
    }

    // Sort the events by their start times
    processedEvents.sort((a, b) => a.start.diff(b.start));

    // Group events by resourceId
    const eventsByResource = new Map();
    processedEvents.forEach((event) => {
      if (!eventsByResource.has(event.resourceId)) {
        eventsByResource.set(event.resourceId, []);
      }
      eventsByResource.get(event.resourceId).push(event);
    });

    // Identify and assign overlap indices within each group
    eventsByResource.forEach((events) => {
      let overlapGroups = [];
      events.forEach((event) => {
        let addedToGroup = false;
        overlapGroups.forEach((group) => {
          if (
            group.some(
              (e) => e.start.isBefore(event.end) && e.end.isAfter(event.start)
            )
          ) {
            group.push(event);
            addedToGroup = true;
          }
        });

        if (!addedToGroup) {
          overlapGroups.push([event]);
        }
      });

      overlapGroups.forEach((group) => {
        group.forEach((event, eventIndex) => {
          event.overlapCount = group.length - 1; // exclude the event itself
          event.overlapIndex = eventIndex + 1; // assign unique index within the group
        });
      });
    });

    setEvents(processedEvents);
  }, [unprocessedEvents, calendarDate, timezone, timeBounds]);

  useEffect(() => {
    calendarContainerRef.current.addEventListener(
      "scroll",
      updateCalendarCoordinates,
      { passive: true }
    );

    window.addEventListener("resize", updateCalendarCoordinates, {
      passive: true,
    });
    window.addEventListener("resize", adjustEventsStyle, { passive: true });

    return () => {
      window.removeEventListener("resize", adjustEventsStyle);
      window.removeEventListener("resize", updateCalendarCoordinates);
    };
  }, [adjustEventsStyle, updateCalendarCoordinates]);

  useEffect(() => {
    if (isCalendarRendered && startHourInView) {
      const foundedTimeSlotIndex = timeSlots.findIndex(
        ({ time }) => time.get("hour") === +startHourInView
      );
      if (foundedTimeSlotIndex > -1)
        timeSlotNodeProps.current[foundedTimeSlotIndex].ref.scrollIntoView({
          behavior: "smooth",
        });
    }
  }, [isCalendarRendered, startHourInView, timeSlots]);

  useEffect(() => {
    adjustEventsStyle();
  }, [adjustEventsStyle, resources]);

  useDndScrolling(autoScroll && calendarContainerRef);

  return {
    calendarContainerRef,
    handleCellRef,
    handleColumnRef,
    handleTimeSlotRef,
    handleOverlayDrop,
    timeSlots,
    isCalendarRendered,
    setIsCalendarRendered,
    getDroppedEventStyles,
    setPreviousCalendarScrollOffset,
    handleResizeEvent,
    events,
    isTouchDevice,
  };
};

export default useSchedular;
