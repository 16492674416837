import { Button, Drawer, Spin } from "antd";

import "./styles.scss";

import { CustomEmpty } from "globalComponents";
import {
  NOTIFICATION_ACTION,
  NOTIFICATION_TITLE,
  SHIFT_STATUS,
} from "./constants";
import useNotificationsDrawer from "./useNotificationsDrawer";
import { getNotificationDesc } from "./utils";

const NotificationsDrawer = ({ onClose, open }) => {
  const {
    notifications,
    handleShiftRequest,
    getIfShiftActionInProgress,
    timezone,
  } = useNotificationsDrawer();

  return (
    <Drawer
      title={<h3 className="m-0">Notifications</h3>}
      open={open}
      onClose={onClose}
      className="notifications-drawer"
    >
      {notifications.length === 0 ? (
        <CustomEmpty text="No Notifications" />
      ) : (
        notifications.map((notification) => (
          <NotificationCard
            timezone={timezone}
            key={notification.id}
            notification={notification}
            handleShiftRequest={handleShiftRequest}
            ifShiftActionInProgress={getIfShiftActionInProgress(notification)}
          />
        ))
      )}
    </Drawer>
  );
};

export default NotificationsDrawer;

const NotificationCard = ({
  notification,
  handleShiftRequest,
  ifShiftActionInProgress,
}) => (
  <Spin spinning={ifShiftActionInProgress}>
    <div className="notification-card">
      <h4 className="m-0 notification-card-title">
        {NOTIFICATION_TITLE[notification?.type]}
      </h4>
      <p
        className="m-0 notification-card-desc subtle-text"
        dangerouslySetInnerHTML={{
          __html: getNotificationDesc(notification),
        }}
      ></p>
      {notification?.status === SHIFT_STATUS.PENDING && (
        <div className="d-flex gap-5">
          <Button
            className="success-btn"
            type="primary"
            onClick={handleShiftRequest(
              notification,
              NOTIFICATION_ACTION.ACCEPT
            )}
            block
          >
            Accept
          </Button>
          <Button
            danger
            onClick={handleShiftRequest(
              notification,
              NOTIFICATION_ACTION.REJECT
            )}
            block
          >
            Reject
          </Button>
        </div>
      )}
    </div>
  </Spin>
);
