import useResource from "./useResource";

const Resource = ({
  customComponent,
  resource,
  handleResourceDropCb,
  allowDropOnSchedular,
}) => {
  const { Component, props = {} } = customComponent;
  const { drop } = useResource({ handleResourceDropCb, resource });

  return (
    <div className="resource" ref={allowDropOnSchedular ? drop : null}>
      {Component ? <Component resource={resource} {...props} /> : resource?.name}
    </div>
  );
};

export default Resource;
